import React from 'react';
import Memory from './Memory.js';
import './main.css';

export default function MemoriesEditor({ isPublic, memories, swapMemories, updateMemory, deleteMemory, addMemory }) {
  return (
    <div>
      {memories.map((memory, idx) => {
        const memoryId = isPublic ? `public-memory-${idx}` : `private-memory-${idx}`;
        return (
          <Memory
           memory={memory}
           memoryId={memoryId}
           key={memory.memory}
           idx={idx}
           swapMemories={swapMemories}
           updateMemory={updateMemory}
           deleteMemory={deleteMemory}
          />
        );
      })}
      <div className="new-memory-container">
        <button className="new-memory-button" onClick={addMemory} >
          <i className="new-memory-icon fas fa-plus-circle"></i>
          New Memory
        </button>
      </div>
    </div>
  );
}